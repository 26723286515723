import React from "react";
import * as Animate from "react-reveal";
import BigEnergyImage from "../../assets/images/bigenergy.webp";

const RentToOwn = () => {
  return (
    <section className="relative bg-cstm-green rent-to-own-bg py-24">
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap items-center">
          <div className="w-full md:w-6/12 xl:w-5/12 ">
            <Animate.Fade bottom>
              <h1 className="text-white text-3xl font-semibold leading-tight md:text-3xl lg:text-4xl lg:leading-tight">
                Rent-To-Own
              </h1>
              <div className="w-full h-1 bg-white mt-4 lg:mt-6 mb-8" />
              <div className="flex">
                <a
                  href="https://docs.google.com/forms/d/1eNwYL6BI_mAOsJGZR_ZmG-WFhh8ZaghaeDs5goAtA4E/edit"
                  target="_blank"
                  className="text-md md:text-lg lg:text-xl lg:leading-7 bg-white px-8 py-4 rounded-full text-cstm-green mt-4 md:mt-6 lg:mt-10"
                >
                  Learn More
                </a>
              </div>
            </Animate.Fade>
          </div>
          <div className="w-full mt-4 md:mt-0 md:absolute md:w-5/12 right-0 md:-bottom-9">
            <div className=" mx-auto mt-8 lg:mt-0">
              <img className="" src={BigEnergyImage} alt="solar rental" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RentToOwn;
