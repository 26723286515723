import React from 'react'
import { Contact } from '../components'
import { Jumbotron } from '../components/LearnComponents'
import CapsuleDistribution from '../components/LearnComponents/CapsuleDistribution'
import RentToOwn from '../components/LearnComponents/RentToOwn'
import RentViaAmbassadors from '../components/LearnComponents/RentViaAmbassadors'
import SolarPower from '../components/LearnComponents/SolarPower'
import Layout from '../containers/Layout'

export default function Learn() {
  return (
    <Layout title={"Rent A Capsule"}>
      <Jumbotron />
      <RentViaAmbassadors />
      <RentToOwn />
      {/* <SolarPower />
      <CapsuleDistribution /> */}
      <Contact />
    </Layout>
  );
}