import { Link } from "gatsby";
import React from "react";
import * as Animate from "react-reveal"

const data = [
    {
        title: "Visit the nearest ambassador to you",
        text: "Locate an ambassador outlet closest to you to place a rental request for the Reeddi Capsule. You can also send a direct message to us via WhatsApp.",
    },
    {
        title: "Fill out the borrower’s form",
        text: "To begin your rental process, fill out a one-time borrower’s form for identity verification.",
    },
    {
        title: "Get your borrower’s card",
        text: "After filling out the form, you will receive a borrower’s card you can use for future rentals.",
    },
    {
        title: "Start renting the Capsule",
        text: "Place a rental request, make payment (via Mobile Money, Cash or Bank Transfer) and pick up your Reeddi Capsule. You now have power in your hands to meet your daily energy needs.",
    },
];

export default function RentViaAmbassadors() {
    return (
        <section className="mt-28 md:mt-32 mb-28 md:mb-64 relative pt-10 pb-16 bg-cover bg-center main-abstract-bg">
            <div className="relative w-11/12 m-auto pt-16 max-w-7xl">
                <div className="w-full flex flex-col md:flex-row justify-between lg:justify-start xl:gap-x-14 items-center">
                    <div className="w-11/12 mx-auto md:mx-0 md:mt-5 pb-4 md:pb-8 md:self-start md:max-w-lg flex flex-col justify-center md:justify-start md:items-start  md:w-[45%]">
                        <Animate.Fade bottom>
                            <h1 className="text-center md:text-left text-3xl leading-tight md:text-4xl lg:text-4xl lg:leading-tight text-white capitalize">
                                Rent <br/><span className="font-semibold">Reeddi Capsules</span>{" "}<br/>from Local{" "}<br/>
                                <span className="font-semibold"> Reeddi Ambassadors</span>
                            </h1>
                            <div className="mx-auto md:mx-0 mt-8 h-[3px] w-10/12 bg-white" />
                            <p className="text-center md:text-left max-w-[450px] lg:max-w-[550px] text-sm-15 md:text-base lg:text-lg lg:leading-8 text-white mt-4 md:mt-6 lg:mt-10 capitalize">
                                You don’t want to go digital? We have an alternative for you.
                                Our ambassadors drive the Reeddi Capsule adoption and will
                                help you throughout the Capsule rental process.
                            </p>
                        </Animate.Fade>
                    </div>

                    <div className="relative md:absolute md:right-0 md:-mt-12 flex flex-col items-start relative md:w-[45%] md:ml-4 mt-10 md:mt-0 space-y-8">
                        {data.map((e, i) => (
                            <Animate.Fade bottom>
                                <div className="flex transition-all ease-in-out text-[white] justify-center text-cstm-grey px-2 md:px-6 py-4 md:py-6 rounded-md bg-white" style={{ boxShadow: "0px 31.0344px 39.9014px rgba(224, 224, 224, 0.25)" }}>
                                    <p className="text-cstm-green flex justify-center items-start mx-4 mt-4 text-4xl md:text-5xl font-bold">
                                        {i + 1}
                                    </p>
                                    <div className="flex flex-col text-cstm-grey">
                                        <p className="pt-1 font-semibold md:text-xl text-lg text-[black]">
                                            {e.title}
                                        </p>
                                        <p className="pt-2 text-[13px] lg:text-sm-15 xl:text-base xl:leading-7">{e.text}</p>
                                    </div>
                                </div>
                            </Animate.Fade>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
}
