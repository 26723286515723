import { Link } from "gatsby";
import React from "react";
import * as Animate from "react-reveal"

import TvIcon from "../../assets/images/learn/tv.svg"
import SearchIcon from "../../assets/images/learn/search.svg"
import PointerIcon from "../../assets/images/learn/pointer 1.svg"
import ApproveIcon from "../../assets/images/learn/approve.svg"

import BgShapeImage from "../../assets/images/bg-shapes.png"
import RentalImage from "../../assets/images/rental.webp"

const data = [
    {
        title: (
            <p className="font-medium text-lg xl:text-[21px] text-[#051D29] tracking-tighter">
                Visit the website
            </p>
        ),
        img: TvIcon,
        color: "border-[#A6F4C5]",
        text: (
            <>
                Go to{" "}
                <Link
                    target={"_blank"}
                    rel="noopener noreferrer"
                    className="text-cstm-green underline underline-offset-2"
                    to="https://www.tempown.com"
                >
                    www.tempown.com
                </Link>
                . Create an account, complete your KYC verification and set up your
                profile.
            </>
        ),
    },
    {
        title: (
            <p className="font-medium text-lg xl:text-[21px] text-[#051D29] tracking-tighter">
                Search for Product{" "}
            </p>
        ),
        img: SearchIcon,
        color: "border-[#FEDF89]",
        text: (
            <>
                Use the search bar or check popular items to find the Reeddi Product.
                Select it, read the details and check for availability.
            </>
        ),
    },
    {
        title: (
            <p className="font-medium text-lg xl:text-[21px] text-[#051D29] tracking-tighter">
                Choose the nearest location
                {/* Choose a location nearby */}
            </p>
        ),
        img: PointerIcon,
        color: "border-[#A6F4C5]",
        text: (
            <>
                Proximity matters. Make sure to select the state and city closest to
                your pick-up.
            </>
        ),
    },
    {
        title: (
            <p className="font-medium text-lg xl:text-[21px] text-[#051D29] tracking-tighter">
                <span className="text-cstm-green font-medium text-lg xl:text-[21px] text-[#051D29] tracking-tighter">
                    Receive
                </span>{" "}
                your Item
            </p>
        ),
        img: ApproveIcon,
        color: "border-[#FEDF89]",
        text: (
            <>
                Once your rental request is confirmed, check out and make payment. Pick
                up your item and enjoy reliable electricity on the go!
            </>
        ),
    },
];

export default function Jumbotron() {
    return (
        <main
            className="bg-[white] pt-4 md:pt-20 bg-cover bg-center md:pb-14 relative"
        >
            <div className="container px-4 md:px-6 xl:px-0 mx-auto py-16">
                <div className="w-full learn-abstract-bg">
                    <div className="mt-10 md:mt-0  pb-10 lg:pb-0 text-center">
                        <Animate.Fade bottom>
                            <h1 className="text-3xl font-semibold leading-tight md:text-4xl xl:text-5xl lg:leading-tight">
                                Rent on <span className="text-cstm-green">TempOwn</span>
                            </h1>
                            <p className="w-10/12 md:w-8/12 lg:w-7/12 xl:w-6/12 mx-auto capitalize text-sm-15 md:text-base text-cstm-grey lg:text-lg mt-4 md:mt-6 lg:mt-8">
                                Renting on TempOwn allows you to manage your our products rental digitally. It’s easy, secure and fully insured.
                            </p>

                            <div className="mt-20 relative">
                                <img src={RentalImage} alt="Rent on TempOwn" />
                            </div>
                        </Animate.Fade>
                    </div>
                </div>

                {/* //data map */}
                <div className="mt-10 md:mt-20 lg:mt-24 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mx-auto">
                    {data.map((e) => (
                        <Animate.Fade bottom>
                            <div
                                className={`bg-[white] m-2 mb-10 md:mb-0 rounded-md pb-8 px-3 md:px-6 py-6 shadow-[0px 17.2903px 40px rgba(18, 131, 36, 0.1)] `}
                                style={{
                                    boxShadow: "0px 17.2903px 40px rgba(18, 131, 36, 0.1)",
                                }}
                            >
                                <div className=" flex justify-start items-center h-14">
                                    <img src={e.img} />
                                </div>
                                <br />
                                {e.title}
                                <p className="text-cstm-grey text-[15px] mt-4 h-[6rem]">{e.text}</p>

                                <div className="cstm-tempown-gradient-dark h-3 w-full mt-6" />
                            </div>
                        </Animate.Fade>
                    ))}
                </div>
            </div>
        </main>
    );
}
